import { calculateDecimal } from "../../../../../utils/calculateDecimal";
import { cutWithoutRounding } from "../../../../../utils/cutWithoutRounding";

const useTableContent = (pureData, balancesData) => {
  const RFQvalues = pureData?.filter((el) => el?.feature === "RFQ");

  const RFQbtc = RFQvalues?.filter((el) => el?.currency === "BTC")?.reduce(
    (acc, el) => {
      return calculateDecimal(el?.amount, acc, "add");
    },
    0,
  );
  const RFQusd = RFQvalues?.filter((el) => el?.currency === "USD")?.reduce(
    (acc, el) => {
      return calculateDecimal(+el?.amount, +acc, "add");
    },
    0,
  );

  const RFQTotal = calculateDecimal(
    calculateDecimal(
      RFQbtc,
      balancesData?.sfox?.treasury?.currencies?.BTC?.rate,
      "multiply",
    ),
    RFQusd,
    "add",
  );
  const uniqueUsersMapRFQ = new Map();

  RFQvalues.forEach((item) => {
    uniqueUsersMapRFQ.set(item.user.id, { id: item.user.id });
  });

  const uniqueUsersArrRFQ = Array.from(uniqueUsersMapRFQ.values());
  const RFQTable = [
    { key: "RFQ fees", value: `$${cutWithoutRounding(RFQTotal, 2)}` },
    { key: "BTC RFQ fees", value: `BTC ${cutWithoutRounding(RFQbtc, 8)}` },
    { key: "USD RFQ fees", value: `$${cutWithoutRounding(RFQusd, 2)}` },
    { key: "RFQ fee transactions", value: RFQvalues?.length },
    {
      key: "RFQ fee/ transaction",
      value: `$${cutWithoutRounding(
        calculateDecimal(RFQTotal, RFQvalues?.length, "divide"),
        2,
      )}`,
    },
    { key: "RFQ fee users", value: uniqueUsersArrRFQ?.length },
    {
      key: "RFQ fee/ user",
      value: `$${cutWithoutRounding(
        calculateDecimal(RFQTotal, uniqueUsersArrRFQ?.length, "divide"),
        2,
      )}`,
    },
  ];

  const withdrawValues = pureData?.filter((el) => el?.feature === "WITHDRAW");
  const withdrawBtc = withdrawValues
    ?.filter((el) => el?.currency === "BTC")
    ?.reduce((acc, el) => {
      return calculateDecimal(el?.amount, acc, "add");
    }, 0);
  const withdrawUsd = withdrawValues
    ?.filter((el) => el?.currency === "USD")
    ?.reduce((acc, el) => {
      return calculateDecimal(el?.amount, acc, "add");
    }, 0);
  const withdrawTotal = calculateDecimal(
    calculateDecimal(
      withdrawBtc,
      balancesData?.sfox?.treasury?.currencies?.BTC?.rate,
      "multiply",
    ),
    withdrawUsd,
    "add",
  );
  const uniqueUsersMapWithdraw = new Map();

  withdrawValues.forEach((item) => {
    uniqueUsersMapWithdraw.set(item.user.id, { id: item.user.id });
  });

  const uniqueUsersArrWithdraw = Array.from(uniqueUsersMapWithdraw.values());
  const withdrawTable = [
    { key: "Withdraw fees", value: `$${cutWithoutRounding(withdrawTotal, 2)}` },
    {
      key: "Total BTC fees",
      value: `BTC ${cutWithoutRounding(withdrawBtc, 8)}`,
    },
    { key: "Total USD fees", value: `$${cutWithoutRounding(withdrawUsd, 2)}` },
    { key: "Total fee transactions", value: withdrawValues?.length },
    {
      key: "Total fee/ transaction",
      value: `$${cutWithoutRounding(
        calculateDecimal(withdrawTotal, withdrawValues?.length, "divide"),
        2,
      )}`,
    },
    { key: "Total fee users", value: uniqueUsersArrWithdraw?.length },
    {
      key: "Total fee/ user",
      value: `$${cutWithoutRounding(
        calculateDecimal(
          withdrawTotal,
          uniqueUsersArrWithdraw?.length,
          "divide",
        ),
        2,
      )}`,
    },
  ];

  const totalTable = [
    {
      key: "Total fees",
      value: `$${cutWithoutRounding(
        calculateDecimal(withdrawTotal, RFQTotal, "add"),
        2,
      )}`,
    },
    {
      key: "Total BTC fees",
      value: `BTC ${cutWithoutRounding(
        calculateDecimal(withdrawBtc, RFQbtc, "add"),
        8,
      )}`,
    },
    {
      key: "Total USD fees",
      value: `$${cutWithoutRounding(
        calculateDecimal(withdrawUsd, RFQusd, "add"),
        2,
      )}`,
    },
    {
      key: "Total fee transactions",
      value: withdrawValues?.length + RFQvalues?.length,
    },
    {
      key: "Total fee/ transaction",
      value: `$${cutWithoutRounding(
        calculateDecimal(
          calculateDecimal(withdrawTotal, RFQTotal, "add"),
          withdrawValues?.length + RFQvalues?.length,
          "divide",
        ),
        2,
      )}`,
    },
    {
      key: "Total fee users",
      value: uniqueUsersArrWithdraw?.length + uniqueUsersArrRFQ?.length,
    },
    {
      key: "Total fee/ user",
      value: `$${cutWithoutRounding(
        calculateDecimal(
          calculateDecimal(withdrawTotal, RFQTotal, "add"),
          uniqueUsersArrWithdraw?.length + uniqueUsersArrRFQ?.length,
          "divide",
        ),
        2,
      )}`,
    },
  ];

  return {
    totalTable,
    RFQTable,
    withdrawTable,
  };
};

export default useTableContent;
