import { calculateDecimal } from "../../../../../utils/calculateDecimal";
import { cutWithoutRounding } from "../../../../../utils/cutWithoutRounding";

const useTableContent = (pureData) => {
  const totalTable = [
    {
      key: "Total Deposits",
      value: `$${cutWithoutRounding(
        calculateDecimal(
          pureData?.total?.data?.USD?.amount ?? 0,
          pureData?.total?.data?.BTC?.value ?? 0,
          "add",
        ),
        2,
      )}`,
    },
    {
      key: "Count",
      value:
        (pureData?.total?.data?.USD?.count ?? 0) +
        (pureData?.total?.data?.BTC?.count ?? 0),
    },
  ];
  const USDTable = [
    {
      key: "Total USD",
      value: `$${
        cutWithoutRounding(pureData?.total?.data?.USD?.amount, 2) || 0
      }`,
    },
    {
      key: "USD Count",
      value: pureData?.total?.data?.USD?.count,
    },
  ];
  const BTCTable = [
    {
      key: "Total BTC",
      value: `BTC ${cutWithoutRounding(pureData?.total?.data?.BTC?.amount, 8)}`,
    },
    {
      key: "BTC Count",
      value: pureData?.total?.data?.BTC?.count,
    },
  ];

  return {
    totalTable,
    USDTable,
    BTCTable,
  };
};

export default useTableContent;
