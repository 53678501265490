import React from "react";
import "./WithdrawLegend.scss";

const WithdrawLegend = () => {
  return (
    <div className="legend-withdraw__wrapper">
      <span className="legend-withdraw__element">Date</span>
      <span className="legend-withdraw__element">Withdrdawals (T/C)</span>
      <span className="legend-withdraw__element">USD (T/C)</span>
      <span className="legend-withdraw__element">Wire (T/C)</span>
      <span className="legend-withdraw__element">ACH (T/C)</span>
      <span className="legend-withdraw__element">BTC (T/C)</span>
    </div>
  );
};

export default WithdrawLegend;
