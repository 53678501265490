import React from "react";
import "./DepositDetailsLegend.scss";

const DepositDetailsLegend = () => {
  return (
    <div className="legend-monetiz__wrapper">
      <span className="legend-deposit__element">Deposits (T/C)</span>
      <span className="legend-deposit__element">USD (T/C)</span>
      <span className="legend-deposit__element">BTC (T/C)</span>
      <span className="legend-deposit__element">User</span>
    </div>
  );
};

export default DepositDetailsLegend;
