import React from "react";
import "./TotalTransactionsLegend.scss";

const TotalTransactionsLegend = () => {
  return (
    <div className="legend-total-transactions__wrapper">
      <span className="legend-total-transactions__element">Date</span>
      <span className="legend-total-transactions__element">Volume</span>
      <span className="legend-total-transactions__element">Count</span>
    </div>
  );
};

export default TotalTransactionsLegend;
