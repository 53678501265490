import React, { useState } from "react";
import webSocketService from "../../services/WebSocketService";
import UserFilters from "../UserFilters/UserFilters";
import UsersTable from "../UsersTable/UsersTable";
import "./Users.scss";
import Footer from "../Footer/Footer";

const Users = ({ navigate }) => {
  const [users, setUsers] = useState([]);

  const findUsers = async (data) => {
    const usersData = await webSocketService.sendMessage("findUsers", "c", {
      ...data,
      referral: { alias: data?.referral },
    });
    setUsers(usersData);
  };
  return (
    <div className="users__wrapper">
      <UserFilters onSubmit={findUsers} setUsers={setUsers} />
      <UsersTable users={users} />
      <Footer navigate={navigate} />
    </div>
  );
};

export default Users;
