import React from "react";

import "./StatisticTable.scss";

const StatisticTable = ({ data }) => {
  return (
    <div className="st-table__wrapper">
      {data?.map(({ key, value }, i) => (
        <div
          key={key}
          className={`st-table__item ${
            i === 0 ? "st-table__item__first" : ""
          } ${i > 1 ? "st-table__item__line" : ""}`}
        >
          <span
            className={`st-table__key ${i === 0 ? "st-table__key__first" : ""}`}
          >
            {key}
          </span>
          <span
            className={`st-table__value ${
              i === 0 ? "st-table__value__first" : ""
            }`}
          >
            {(typeof value === "string" && value?.includes("NaN")) || !value
              ? "-"
              : value}
          </span>
        </div>
      ))}
    </div>
  );
};

export default StatisticTable;
