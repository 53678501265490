import React from "react";
import parseDate from "../../utils/parseDate";
import parseTime from "../../utils/parseTime";
import "./IPItem.scss";

const IPItem = ({ address, lastUsed }) => {
  return (
    <div className="ip-item__container">
      <span className="ip-item__text">{address}</span>
      <span className="ip-item__text">
        {parseDate(lastUsed)}
        {" "}
        {parseTime(lastUsed, false)}
        {" "}
        UTC
      </span>
    </div>
  );
};

export default IPItem;
