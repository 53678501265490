import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import DepositsDetails from "../pages/depositsDetails/DepositsDetails";
import Limits from "../pages/limits/Limits";
import Login from "../pages/login/Login";
import Mfa from "../pages/mfa/Mfa";
import MfaConnect from "../pages/mfaConnect/MfaConnect";
import MonetizationDetails from "../pages/monetizationDetails/MonetizationDetails";
import ReferredUsers from "../pages/referredUsers/ReferredUsers";
import Statistics from "../pages/statistics/Statistics";
import Totals from "../pages/totals/Totals";
import Transactions from "../pages/transactions/Transactions";
import User from "../pages/user/User";
import UsersByLimits from "../pages/usersByLimits/UsersByLimits";
import VolumeDetails from "../pages/volumeDetails/VolumeDetails";
import WithdrawDetails from "../pages/withdrawDetails/WithdrawDetails";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/mfaconnect",
    element: <MfaConnect />,
  },
  {
    path: "/mfa",
    element: <Mfa />,
  },
  {
    path: "*",
    element: <></>,
  },
]);

export const privateRouter = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/user/:userId",
    element: <User />,
  },
  {
    path: "/transactions/:userId/:initialFilter?",
    element: <Transactions />,
  },
  {
    path: "/limits",
    element: <Limits />,
  },
  {
    path: "/usersByLimit/:limit",
    element: <UsersByLimits />,
  },
  {
    path: "/totals/:type",
    element: <Totals />,
  },
  {
    path: "/volumeDetails/:date",
    element: <VolumeDetails />,
  },
  {
    path: "/statistics",
    element: <Statistics />,
  },
  {
    path: "/referredUsers/:id/:type",
    element: <ReferredUsers />,
  },
  {
    path: "/monetizationDetails",
    element: <MonetizationDetails />,
  },
  {
    path: "/depositDetails",
    element: <DepositsDetails />,
  },
  {
    path: "/withdrawDetails",
    element: <WithdrawDetails />,
  },
  {
    path: "*",
    element: <></>,
  },
]);
