import React from "react";
import { statisticFiltersList } from "../../utils/constants/constants";
import "./StatisticFilters.scss";

const StatisticFilters = ({ tab, setTab }) => {
  return (
    <div className="st-filters__wrapper">
      {statisticFiltersList.map((el) => (
        <span
          key={el}
          className={`st-filters__element ${
            tab === el ? "st-filters__element-active" : ""
          }`}
          onClick={() => setTab(el)}
        >
          <span>{el}</span>
        </span>
      ))}
    </div>
  );
};

export default StatisticFilters;
