import React from "react";
import { useNavigate } from "react-router-dom";
import { calculateDecimal } from "../../utils/calculateDecimal";
import { cutWithoutRounding } from "../../utils/cutWithoutRounding";
import parseDate from "../../utils/parseDate";
import Button from "../Button/Button";
import "./WithdrawItem.scss";

const WithdrawItem = ({ index, el, dataACH, dataWire }) => {
  const navigate = useNavigate();

  const wrapperClass = `withdraw-item__wrapper ${
    index % 2 === 0 ? "withdraw-item" : "withdraw-item__dark"
  }`;

  const item = el[1]?.data;
  const USDCount = item?.USD?.count ?? 0;
  const BTCCount = item?.BTC?.count ?? 0;

  return (
    <div className={wrapperClass}>
      <span className="withdraw-item__element withdraw-item__element-justify-center">
        {parseDate(el[0])}
      </span>
      <span className="withdraw-item__element">
        <span>
          $
          {cutWithoutRounding(
            calculateDecimal(
              item?.USD?.amount || 0,
              item?.BTC?.value || 0,
              "add",
            ) || 0,
            2,
          )}
        </span>
        <span>{USDCount + BTCCount}</span>
      </span>
      <span className="withdraw-item__element">
        <span>${cutWithoutRounding(item?.USD?.amount ?? 0, 2)}</span>
        <span>{item?.USD?.count ?? 0}</span>
      </span>

      <span className="withdraw-item__element">
        <span>
          $
          {cutWithoutRounding(
            dataWire?.grouped?.[el[0]]?.data?.USD?.amount ?? 0,
            2,
          )}
        </span>
        <span>{dataWire?.grouped?.[el[0]]?.data?.USD?.count ?? 0}</span>
      </span>
      <span className="withdraw-item__element">
        <span>
          $
          {cutWithoutRounding(
            dataACH?.grouped?.[el[0]]?.data?.USD?.amount ?? 0,
            2,
          )}
        </span>
        <span>{dataACH?.grouped?.[el[0]]?.data?.USD?.count ?? 0}</span>
      </span>

      <span className="withdraw-item__element">
        <span>BTC {cutWithoutRounding(item?.BTC?.amount ?? 0, 8)}</span>
        <span>{item?.BTC?.count ?? 0}</span>
      </span>

      <Button
        wrapperStyles="withdraw-item__button"
        labelStyles="withdraw-item__label"
        label="Details"
        onPress={() =>
          navigate(`/withdrawDetails`, {
            state: {
              date: el[0],
            },
          })
        }
      />
    </div>
  );
};

export default WithdrawItem;
