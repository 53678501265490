import {
  endOfMonth,
  formatISO,
  startOfMonth,
  subDays,
  subMonths,
} from "date-fns";
import { useState } from "react";
import webSocketService from "../../../../../services/WebSocketService";

const useWithdraw = () => {
  const filterTypeSaved = localStorage.getItem("filterTypeWithdraw");
  const fromSaved = localStorage.getItem("fromWithdraw");
  const toSaved = localStorage.getItem("toWithdraw");
  const savedSelectorValue = filterTypeSaved || "past7";
  const [data, setData] = useState([]);
  const [dataACH, setDataACH] = useState([]);
  const [dataWire, setDataWire] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fromDate, setFromDate] = useState(subDays(new Date(), 7));
  const [toDate, setToDate] = useState(new Date());
  const [selectorValue, setSelectorValue] = useState(savedSelectorValue);
  const [showRangePicker, setShowRangePicker] = useState(false);

  const getWithdrawalsData = async (from, to) => {
    const bodyTotal = {
      type: "withdrawals",
      // subType: "ach",
      from: formatISO(new Date(from)),
      to: formatISO(new Date(to)),
      groupBy: "date",
      currencies: ["BTC", "USD"],
    };

    const depositsData = await webSocketService.sendMessage(
      "getStatistic",
      "c",
      bodyTotal,
    );

    const depositsDataACH = await webSocketService.sendMessage(
      "getStatistic",
      "c",
      { ...bodyTotal, subType: "ach" },
    );
    const depositsDataWire = await webSocketService.sendMessage(
      "getStatistic",
      "c",
      { ...bodyTotal, subType: "wire" },
    );

    if (typeof totals === "string") {
      setData([]);
    } else {
      localStorage.setItem("filterTypeWithdraw", selectorValue);
      localStorage.setItem("fromWithdraw", from);
      localStorage.setItem("toWithdraw", to);

      setData(depositsData);
      setDataACH(depositsDataACH);
      setDataWire(depositsDataWire);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const getDateRange = (value) => {
    const today = new Date();
    let startDate, endDate;

    switch (value) {
      case "thisMonth":
        startDate = startOfMonth(today);
        endDate = today;
        break;
      case "lastMonth":
        const lastMonthDate = subMonths(today, 1);
        startDate = startOfMonth(lastMonthDate);
        endDate = endOfMonth(lastMonthDate);
        break;
      case "past7":
        startDate = subDays(today, 7);
        endDate = today;
        break;
      case "past30":
        startDate = subDays(today, 30);
        endDate = today;
        break;
      case "past90":
        startDate = subDays(today, 90);
        endDate = today;
        break;
      case "customized":
        startDate = null;
        endDate = null;
        break;
      default:
        startDate = null;
        endDate = null;
    }
    setFromDate(startDate);
    setToDate(endDate);
    return { startDate, endDate };
  };
  return {
    filterTypeSaved,
    fromSaved,
    toSaved,
    fromDate,
    toDate,
    isLoaded,
    selectorValue,
    isLoading,
    data,
    showRangePicker,
    dataACH,
    dataWire,
    setSelectorValue,
    getWithdrawalsData,
    setIsLoaded,
    setShowRangePicker,
    getDateRange,
    setFromDate,
    setToDate,
  };
};

export default useWithdraw;
