import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./RefStatItem.scss";
import webSocketService from "../../services/WebSocketService";

const RefStatItem = ({ item, index, onEditSuccess }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  useEffect(() => {
    (async () => {
      const userData = await webSocketService.sendMessage("getUser", "c", {
        userId: item?.id,
      });

      setUser(userData);
    })();
  }, []);

  return (
    <div
      className={`ref-stat-item__wrapper ${
        index % 2 === 0 ? "ref-stat-item" : "ref-stat-item__dark"
      }`}
    >
      <span className={`ref-stat-item__element ref-stat-item__element-bold`}>
        <span
          onClick={() => navigate(`/user/${item.id}`)}
          className="ref-stat-item__underline"
        >
          {item?.email}
        </span>
      </span>
      <span className="ref-stat-item__element">
        <span
          className="ref-stat-item__underline"
          onClick={() =>
            navigate(`/referredUsers/${user?.referral?.codes?.[0]?.code}/alias`)
          }
        >
          {user?.referral?.codes?.[0]?.code}
        </span>
        <span className="ref-stat-item__text">
          {user?.referral?.codes?.[0]?.count}
        </span>
      </span>
      <span className="ref-stat-item__element">
        <span
          className="ref-stat-item__underline"
          onClick={() =>
            navigate(`/referredUsers/${user?.referral?.codes?.[1]?.code}/alias`)
          }
        >
          {user?.referral?.codes?.[1]?.code}
        </span>
        <span className="ref-stat-item__text">
          {user?.referral?.codes?.[1]?.count}
        </span>
      </span>
    </div>
  );
};

export default RefStatItem;
