import React from "react";
import "./ReferralLegend.scss";

const ReferralLegend = () => {
  return (
    <div className="legend-ref__wrapper">
      <span className="legend-ref__element">User</span>
      <span className="legend-ref__element">Regular RC/Count</span>
      <span className="legend-ref__element">User-friendly RC/Count</span>
    </div>
  );
};

export default ReferralLegend;
