import React from "react";
import { useNavigate } from "react-router-dom";
import { calculateDecimal } from "../../utils/calculateDecimal";
import { cutWithoutRounding } from "../../utils/cutWithoutRounding";
import "./WithdrawDetailsItem.scss";

const WithdrawDetailsItem = ({ index, el, dataACH, dataWire }) => {
  const navigate = useNavigate();

  const wrapperClass = `wd-det-item__wrapper ${
    index % 2 === 0 ? "wd-det-item" : "wd-det-item__dark"
  }`;

  const item = el[1]?.data;
  const email = el[1]?.email;
  const USDCount = item?.USD?.count ?? 0;
  const USDACHCount = dataACH?.grouped?.[el[0]]?.data?.USD?.count ?? 0;
  const USDWireCount = dataWire?.grouped?.[el[0]]?.data?.USD?.count ?? 0;
  const BTCCount = item?.BTC?.count ?? 0;

  return (
    <div className={wrapperClass}>
      <span className="wd-det-item__element">
        <span>
          $
          {cutWithoutRounding(
            calculateDecimal(
              item?.USD?.amount || 0,
              item?.BTC?.value || 0,
              "add",
            ) || 0,
            2,
          )}
        </span>
        <span>{USDCount + BTCCount}</span>
      </span>
      <span className="wd-det-item__element">
        <span>${cutWithoutRounding(item?.USD?.amount ?? 0, 2)}</span>
        <span>{USDCount}</span>
      </span>

      <span className="wd-det-item__element">
        <span>
          $
          {cutWithoutRounding(
            dataWire?.grouped?.[el[0]]?.data?.USD?.amount ?? 0 ?? 0,
            2,
          )}
        </span>
        <span>{USDWireCount}</span>
      </span>
      <span className="wd-det-item__element">
        <span>
          $
          {cutWithoutRounding(
            dataACH?.grouped?.[el[0]]?.data?.USD?.amount ?? 0 ?? 0,
            2,
          )}
        </span>
        <span>{USDACHCount}</span>
      </span>

      <span className="wd-det-item__element">
        <span>BTC {cutWithoutRounding(item?.BTC?.amount ?? 0, 8)}</span>
        <span>{BTCCount}</span>
      </span>
      <span
        onClick={() => navigate(`/user/${el[0]}`)}
        className="wd-det-item__element wd-det-item__element-justify-center wd-det-item__element-link"
      >
        {email}
      </span>
    </div>
  );
};

export default WithdrawDetailsItem;
