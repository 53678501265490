import React from "react";
import TableItemUser from "../TableItemUser/TableItemUser";
import TableLegendUsers from "../TableLegendUsers/TableLegendUsers";
import "./UsersTable.scss";
import XMLFile from "../../assets/svgs/xmlFile.svg";
import CsvDownloader from "react-csv-downloader";
import { columnsUsersCSV } from "../../utils/constants/constants";

const UsersTable = ({ users = [] }) => {
  const tableData = users?.map((item) => {
    return {
      first: `${item.firstName} ${item.lastName}`,
      second: item.email,
      third: item.userId,
      fourth: item.referral,
      fifth: item.type,
      sixth: item?.last?.login,
    };
  });

  return (
    <div className="users-table__container">
      <div className="users-table__top-block">
        <div className="users-table__title-container">
          <span className="users-table__title">Search results:</span>
          <span>{`${users?.length} results`}</span>
        </div>
        {users?.length > 0 && (
          <div className="users-table__download">
            <CsvDownloader
              filename="Users"
              extension=".csv"
              separator=";"
              wrapColumnChar="'"
              columns={columnsUsersCSV}
              datas={tableData}
            >
              <div className="users-table__download">
                <img
                  src={XMLFile}
                  alt="arrow"
                  className="user-table__xml-icon"
                />
                <span className="users-table__download__label">Download</span>
              </div>
            </CsvDownloader>
          </div>
        )}
      </div>
      {users?.length > 0 && (
        <>
          <TableLegendUsers />
          {users &&
            users?.map((el, index) => (
              <TableItemUser item={el} key={index} index={index} />
            ))}
        </>
      )}
    </div>
  );
};

export default UsersTable;
