import React, { useState } from "react";
import "./Statistics.scss";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import StatisticFilters from "../../components/StatisticFilters/StatisticFilters";
import ReferralStats from "./tabs/ReferralStats/ReferralStats";
import Monetization from "./tabs/Monetization/Monetization";
import Deposit from "./tabs/Deposit/Deposit";
import Withdraw from "./tabs/Withdraw/Withdraw";

const Statistics = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState("Monetization");

  const renderContent = () => {
    switch (tab) {
      case "Monetization":
        return <Monetization />;
      case "Referral codes":
        return <ReferralStats />;
      case "Deposit":
        return <Deposit />;
      case "Withdrawal":
        return <Withdraw />;
      default:
        return null;
    }
  };

  return (
    <div className="stats__wrapper">
      <div className="stats__content">
        <Header withLogo navigate={navigate} />
        <span className="stats__header">Statistics</span>
        <StatisticFilters tab={tab} setTab={setTab} />
        {renderContent()}
      </div>
      <Footer navigate={navigate} />
    </div>
  );
};

export default Statistics;
