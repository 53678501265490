import React from "react";
import "./WithrawDetailsLegend.scss";

const WithrawDetailsLegend = () => {
  return (
    <div className="legend-withraw-det__wrapper">
      <span className="legend-withraw-det__element">Deposits (T/C)</span>
      <span className="legend-withraw-det__element">USD (T/C)</span>
      <span className="legend-withraw-det__element">Wire (T/C)</span>
      <span className="legend-withraw-det__element">ACH (T/C)</span>
      <span className="legend-withraw-det__element">BTC (T/C)</span>
      <span className="legend-withraw-det__element">User</span>
    </div>
  );
};

export default WithrawDetailsLegend;
