import React from "react";
import { useNavigate } from "react-router-dom";
import { calculateDecimal } from "../../utils/calculateDecimal";
import { cutWithoutRounding } from "../../utils/cutWithoutRounding";
import parseDate from "../../utils/parseDate";
import Button from "../Button/Button";
import "./DepositItem.scss";

const DepositItem = ({ index, el }) => {
  const navigate = useNavigate();

  const wrapperClass = `deposit-item__wrapper ${
    index % 2 === 0 ? "deposit-item" : "deposit-item__dark"
  }`;

  const item = el[1]?.data;
  const USDCount = item?.USD?.count ?? 0;
  const BTCCount = item?.BTC?.count ?? 0;

  return (
    <div className={wrapperClass}>
      <span className="deposit-item__element deposit-item__element-justify-center">
        {parseDate(el[0])}
      </span>
      <span className="deposit-item__element">
        <span>
          $
          {cutWithoutRounding(
            calculateDecimal(
              item?.USD?.amount || 0,
              item?.BTC?.value || 0,
              "add",
            ) || 0,
            2,
          )}
        </span>
        <span>{USDCount + BTCCount}</span>
      </span>
      <span className="deposit-item__element">
        <span>${cutWithoutRounding(item?.USD?.amount ?? 0, 2)}</span>
        <span>{item?.USD?.count ?? 0}</span>
      </span>
      <span className="deposit-item__element">
        <span>BTC {cutWithoutRounding(item?.BTC?.amount ?? 0, 8)}</span>
        <span>{item?.BTC?.count ?? 0}</span>
      </span>

      <Button
        wrapperStyles="deposit-item__button"
        labelStyles="deposit-item__label"
        label="Details"
        onPress={() =>
          navigate(`/depositDetails`, {
            state: {
              date: el[0],
            },
          })
        }
      />
    </div>
  );
};

export default DepositItem;
