import React from "react";
import { useNavigate } from "react-router-dom";
import { calculateDecimal } from "../../utils/calculateDecimal";
import { cutWithoutRounding } from "../../utils/cutWithoutRounding";
import "./DepositDetailsItem.scss";

const DepositDetailsItem = ({ index, el, date }) => {
  const navigate = useNavigate();

  const wrapperClass = `deposit-item__wrapper ${
    index % 2 === 0 ? "deposit-item" : "deposit-item__dark"
  }`;

  const item = el[1]?.data;
  const email = el[1]?.email;
  const USDCount = item?.USD?.count ?? 0;
  const BTCCount = item?.BTC?.count ?? 0;

  return (
    <div className={wrapperClass}>
      <span className="deposit-item__element">
        <span>
          $
          {cutWithoutRounding(
            calculateDecimal(
              item?.USD?.amount || 0,
              item?.BTC?.value || 0,
              "add",
            ) || 0,
            2,
          )}
        </span>
        <span>{USDCount + BTCCount}</span>
      </span>
      <span className="deposit-item__element">
        <span>${cutWithoutRounding(item?.USD?.amount ?? 0, 2)}</span>
        <span>{USDCount}</span>
      </span>
      <span className="deposit-item__element">
        <span>BTC {cutWithoutRounding(item?.BTC?.amount ?? 0, 8)}</span>
        <span>{BTCCount}</span>
      </span>
      <span
        onClick={() => navigate(`/user/${el[0]}`)}
        className="deposit-item__element deposit-item__element-justify-center deposit-item__element-link"
      >
        {email}
      </span>
    </div>
  );
};

export default DepositDetailsItem;
