import React from "react";
import { useNavigate } from "react-router-dom";
import { cutWithoutRounding } from "../../utils/cutWithoutRounding";
import "./MonetizationDetailsItem.scss";

const MonetizationDetailsItem = ({
  index,
  userId,
  transactionsLength,
  withdraw,
  rfq,
  total,
  el,
}) => {
  const navigate = useNavigate();

  const wrapperClass = `monetiz-item-det__wrapper ${
    index % 2 === 0 ? "monetiz-item-det" : "monetiz-item-det__dark"
  }`;

  return (
    <div className={wrapperClass}>
      <span className="monetiz-item-det__element">
        ${cutWithoutRounding(total, 2)}
      </span>
      <span className="monetiz-item-det__element">
        ${cutWithoutRounding(rfq, 2)}
      </span>
      <span className="monetiz-item-det__element">
        ${cutWithoutRounding(withdraw, 2)}
      </span>
      <span className="monetiz-item-det__element">{transactionsLength}</span>
      <span
        className="monetiz-item-det__element monetiz-item-det__element__underline"
        onClick={() => {
          navigate(`/user/${userId}`);
        }}
      >
        {el.find((item) => item.user.id === userId)?.user?.email}
      </span>
    </div>
  );
};

export default MonetizationDetailsItem;
