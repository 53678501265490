import React, { useEffect } from "react";
import "./Deposit.scss";
import Select from "react-select";
import StatisticTable from "../../../../components/StatisticTable/StatisticTable";
import {
  customStylesSelect,
  loaderOverride,
  totalsRanges,
} from "../../../../utils/constants/constants";
import TotalRangePicker from "../../../../components/TotalRangePicker/TotalRangePicker";
import useTableContent from "./hooks/useTableContent";
import { MoonLoader } from "react-spinners";
import useDeposit from "./hooks/useDeposit";
import DepositLegend from "../../../../components/DepositLegend/DepositLegend";
import DepositItem from "../../../../components/DepositItem/DepositItem";

const Deposit = () => {
  const {
    filterTypeSaved,
    fromSaved,
    toSaved,
    fromDate,
    toDate,
    isLoaded,
    selectorValue,
    isLoading,
    balancesData,
    data,
    showRangePicker,
    setSelectorValue,
    getDepositsData,
    setIsLoaded,
    setShowRangePicker,
    getDateRange,
    setFromDate,
    setToDate,
  } = useDeposit();
  const { USDTable, BTCTable, totalTable } = useTableContent(
    data,
    balancesData,
  );

  useEffect(() => {
    if (filterTypeSaved && filterTypeSaved !== "null") {
      setSelectorValue(filterTypeSaved);
      getDepositsData(fromSaved, toSaved);
    } else if (filterTypeSaved === "null" && fromSaved) {
      setSelectorValue("customized");
      getDepositsData(fromSaved, toSaved);
    } else {
      getDepositsData(fromDate, toDate);
    }
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      if (selectorValue === "customized") {
        setSelectorValue("customized");
        return setShowRangePicker(true);
      }

      if (selectorValue) {
        const range = getDateRange(selectorValue);
        getDepositsData(range.startDate, range.endDate);
      }
    }
  }, [selectorValue]);

  if (isLoading) {
    return (
      <div className="deposit__loader">
        <MoonLoader
          color="#7236CD"
          loading={isLoading}
          cssOverride={loaderOverride}
          size={35}
        />
      </div>
    );
  }
  return (
    <div className="deposit__wrapper">
      <div className="deposit__tables">
        <StatisticTable data={totalTable} />
        <StatisticTable data={USDTable} />
        <StatisticTable data={BTCTable} />
      </div>
      <div className="deposit__selector-wrapper">
        <span className="deposit__selector-label">Period</span>
        <Select
          styles={customStylesSelect}
          options={totalsRanges}
          placeholder={"placeholder"}
          getOptionValue={(option) => option.value}
          onChange={(option) => {
            if (
              option.value === "customized" &&
              selectorValue === "customized"
            ) {
              setShowRangePicker(true);
            }

            setSelectorValue(option.value);
          }}
          value={totalsRanges.find((option) => option.value === selectorValue)}
        />
      </div>
      <div className="deposit__items-table">
        <DepositLegend />
        {data?.grouped
          ? Object.entries(data?.grouped)
              ?.reverse()
              ?.map((el) => <DepositItem el={el} key={el[0]} />)
          : null}
      </div>
      {showRangePicker && (
        <TotalRangePicker
          showModal={showRangePicker}
          setShowModal={setShowRangePicker}
          getData={getDepositsData}
          setFromDate={setFromDate}
          setToDate={setToDate}
          fromDate={fromDate}
          toDate={toDate}
        />
      )}
    </div>
  );
};

export default Deposit;
