import React from "react";
import { useNavigate } from "react-router-dom";
import "./TableItemEmail.scss";

const TableItemEmail = ({ item, index, onEditSuccess }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`ref-email-item__wrapper ${
        index % 2 === 0 ? "ref-email-item__dark" : "ref-email-item"
      }`}
    >
      <span className={`ref-email-item__element ref-email-item__element-bold`}>
        <span
          onClick={() => navigate(`/user/${item.userId}`)}
          className="ref-email-item__underline"
        >
          {item?.email}
        </span>
      </span>
    </div>
  );
};

export default TableItemEmail;
