import React from "react";
import { useNavigate } from "react-router-dom";
import { calculateDecimal } from "../../utils/calculateDecimal";
import { cutWithoutRounding } from "../../utils/cutWithoutRounding";
import parseDate from "../../utils/parseDate";
import Button from "../Button/Button";
import "./MonetizationItem.scss";

const MonetizationItem = ({ index, el, balancesData }) => {
  const navigate = useNavigate();

  const wrapperClass = `monetiz-item__wrapper ${
    index % 2 === 0 ? "monetiz-item" : "monetiz-item__dark"
  }`;

  const countUniqueUsers = (el) => {
    const uniqueUserIds = new Set();
    el.transactions.forEach((transaction) => {
      uniqueUserIds.add(transaction.user.id);
    });

    return uniqueUserIds;
  };

  const uniqueUsers = countUniqueUsers(el);

  const calculateFeeValue = (transactions, feature) => {
    const filteredByFeatureValues = transactions?.filter(
      (el) => el?.feature === feature,
    );
    const amountBtc = filteredByFeatureValues
      ?.filter((el) => el?.currency === "BTC")
      ?.reduce((acc, el) => {
        return calculateDecimal(el?.amount, acc, "add");
      }, 0);
    const amountUsd = filteredByFeatureValues
      ?.filter((el) => el?.currency === "USD")
      ?.reduce((acc, el) => {
        return calculateDecimal(el?.amount, acc, "add");
      }, 0);
    const totalValue = calculateDecimal(
      calculateDecimal(
        amountBtc,
        balancesData?.sfox?.treasury?.currencies?.BTC?.rate,
        "multiply",
      ),
      amountUsd,
      "add",
    );
    return totalValue;
  };

  const totalFees = cutWithoutRounding(
    calculateDecimal(
      calculateFeeValue(el?.transactions, "RFQ"),
      calculateFeeValue(el?.transactions, "WITHDRAW"),
      "add",
    ),
    2,
  );

  return (
    <div className={wrapperClass}>
      <span className="monetiz-item__element">{parseDate(el?.date)}</span>
      <span className="monetiz-item__element">${totalFees}</span>
      <span className="monetiz-item__element">
        ${cutWithoutRounding(calculateFeeValue(el?.transactions, "RFQ"), 2)}
      </span>
      <span className="monetiz-item__element">
        $
        {cutWithoutRounding(calculateFeeValue(el?.transactions, "WITHDRAW"), 2)}
      </span>
      <span className="monetiz-item__element">{el.transactions?.length}</span>
      <span className="monetiz-item__element">{countUniqueUsers(el).size}</span>
      <Button
        wrapperStyles="monetiz-item__button"
        labelStyles="monetiz-item__label"
        label="Details"
        onPress={() =>
          navigate(`/monetizationDetails`, {
            state: {
              item: el,
              balancesData: balancesData,
              uniqueUsers,
              totalFees,
              date: el?.date,
            },
          })
        }
      />
    </div>
  );
};

export default MonetizationItem;
