import React from "react";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import { cutWithoutRounding } from "../../utils/cutWithoutRounding";
import getStatusColor from "../../utils/getStatusColor";
import Resend from "../../assets/svgs/resend.svg";

import "./TransactionItem.scss";
import webSocketService from "../../services/WebSocketService";

const TransactionItem = ({ item, filter, getTransactionsData }) => {
  const onResend = async () => {
    await webSocketService.sendMessage("setTransferStatus", "c", {
      transferId: item.id,
      status: "settled",
    });
    await getTransactionsData();
  };

  const renderAmount = () => {
    if (filter === "sendCashTransfers" || filter === "invoiceTransfers") {
      return `${item?.remittance.currency} ${cutWithoutRounding(
        item?.remittance.amount,
        item?.remittance.currency === "USD" ? 2 : 8,
      )} `;
    }

    return `${item.currency} ${cutWithoutRounding(
      item.amount,
      item.currency === "USD" ? 2 : 8,
    )}`;
  };
  const formattedDate = new Date(item?.created)
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: "UTC",
    })
    ?.replaceAll(".", "/");

  const formattedTime = new Date(item?.created).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "UTC",
  });

  return (
    <div className={`tr-item__wrapper tr-item__dark`}>
      <span className="tr-item__element tr-item__element-bold">
        {item.type === "tbc" ? "TBC" : capitalizeFirstLetter(item.type)}{" "}
        {item?.method ? ` (${item?.method?.toUpperCase()})` : ""}
      </span>
      <span className="tr-item__element">
        {formattedDate} {formattedTime} UTC
      </span>
      <span className="tr-item__element">{renderAmount()}</span>
      <span className="tr-item__element tr-item__element-bigger">
        {item.id}
      </span>
      <span
        className={`tr-item__element tr-item__element-bold ${getStatusColor(
          item.status,
        )} tr-item__element-status`}
      >
        {capitalizeFirstLetter(item.status)}
        {item.type === "tbc" && item.status === "finalized" && (
          <div
            className="tr-item__element tr-item__element-resend"
            onClick={onResend}
            title="Recheck"
          >
            <img src={Resend} alt="resend" />
          </div>
        )}
      </span>
    </div>
  );
};

export default TransactionItem;
