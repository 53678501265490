import React, { useEffect, useState } from "react";
import "./ReferredUsers.scss";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import webSocketService from "../../services/WebSocketService";
import { MoonLoader } from "react-spinners";
import { loaderOverride } from "../../utils/constants/constants";
import Footer from "../../components/Footer/Footer";
import TableItemEmail from "../../components/TableItemEmail/TableItemEmail";
import BalanceItem from "../../components/BalanceItem/BalanceItem";

const ReferredUsers = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const { id, type } = useParams();
  useEffect(() => {
    (async () => {
      const referredUsers = await webSocketService.sendMessage(
        "findUsers",
        "c",
        {
          referral: { [`${type}`]: id },
        },
      );
      setUsers(referredUsers);
    })();
  }, []);
  return !isLoading ? (
    <div className="referred__wrapper">
      <div className="referred__content">
        <Header withLogo navigate={navigate} />

        <div className="referred__top-block">
          <span className="referred__label">Referred using {id}</span>
        </div>

        <div className="referred__total">
          <BalanceItem title="Total Users" balance={users?.length} />
        </div>

        {users?.map((el, index) => (
          <TableItemEmail item={el} index={index} />
        ))}
      </div>
      <Footer navigate={navigate} />
    </div>
  ) : (
    <div className="referred__loader">
      <MoonLoader
        color="#7236CD"
        loading={isLoading}
        cssOverride={loaderOverride}
        size={35}
      />
    </div>
  );
};

export default ReferredUsers;
