import React from "react";
import "./ReferralStats.scss";
import {
  UFPromoUsersDev,
  UFPromoUsersProd,
} from "../../../../utils/constants/constants";
import ReferralLegend from "../../../../components/LNSendsLegend/ReferralLegend";
import RefStatItem from "../../../../components/RefStatItem/RefStatItem";

const ReferralStats = () => {
  const UFPromoUsers = process.env.REACT_APP_SERVER_URL?.includes("dev")
    ? UFPromoUsersDev
    : UFPromoUsersProd;

  return (
    <div>
      <ReferralLegend />
      {UFPromoUsers.map((el) => (
        <RefStatItem item={el} />
      ))}
    </div>
  );
};

export default ReferralStats;
