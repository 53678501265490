import { calculateDecimal } from "./calculateDecimal";
import { cutWithoutRounding } from "./cutWithoutRounding";

export const getTotalVolumeTransactions = (data, isCount = false) => {
  const sum = data.reduce(
    (accumulator, currentValue) =>
      calculateDecimal(
        accumulator,
        isCount ? +currentValue.count : +currentValue.volume,
        "add",
      ),
    0,
  );

  return cutWithoutRounding(sum, 2);
};
