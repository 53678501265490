import React from "react";
import "./DepositLegend.scss";

const DepositLegend = () => {
  return (
    <div className="legend-monetiz__wrapper">
      <span className="legend-deposit__element">Date</span>
      <span className="legend-deposit__element">Deposits (T/C)</span>
      <span className="legend-deposit__element">USD (T/C)</span>
      <span className="legend-deposit__element">BTC (T/C)</span>
    </div>
  );
};

export default DepositLegend;
